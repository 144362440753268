import { isDev } from 'src/utils/utils';
import { saveToSStore } from '../persistence/storage';
import { DATA_STORAGE_KEY as STORAGE_KEY } from './context';

export const initialState = {
  buyHoldData: {},
  buyHoldHorizon: {},
  defaultBuyHoldHorizon: {},
  buyHoldVariationPrice: null,
  commodities: [],
  commodity: {},
  contract: {},
  contractGraphNodes: [],
  contractGraphs: [],
  selectedContractNodes: [],
  contractsByCommodities: [],
  currency: {},
  currentPriceDrivers: [],

  driverStats: [],
  drivers: [],
  features: [],
  flow: {},
  flowHorizons: [],
  flows: [],
  loadingStates: {
    // [KEY]: boolean
  },
  logo: {},
  modelingGroup: null,
  outputs: [],
  priceDriversSelectedHorizon: {},
  runs: [],
  selectedContract: { id: null },
  contractHasProcurementFlow: false,
  selectedFlow: { production_flow: null },
  selectedRun: null,
  prevSelectedRun: null,
  target: {},
  targetOverview: {
    lastPrice: 0,
    priceChange: 0,
    changeRate: 0,
  },
  targets: [],
  user: {},
  windowPriceDrivers: [],
  // Recommendations
  demands: [],
  demandSelectedPeriod: { id: 'all', label: 'all' },
  displayedParameterSetsBenchmark: [],
  displayedParameterSetsForProgram: [],
  filteredParameterSets: [],
  parameterSets: [],
  computedParameterSets: [],
  computedProgramParameterSets: [],
  computedBenchmarkParameterSets: [],
  decisionFlowInfoByParameterSet: [],
  pastPurchases: [],
  pastRecommendations: [],
  defaultPastRecommendations: {},
  pastRecommendationsStats: [],
  defaultPastRecommendationsStats: [],
  globalPastRecommendationsStats: [],
  amountSpentPastRecommendationsStats: [],
  reportingSummaryGlobalCoverage: [],
  reportingSummaryGlobalTransaction: [],
  productionDecisionFlows: [],
  purchaseHistoryPeriod: { id: '7D', label: '7D' },
  recommendationHistoryPeriod: { id: '7D', label: '7D' },
  recommendationDataToDisplay: {},
  recommendationTemplates: [],
  recommendationsRuns: [],
  recommendationProblemContracts: [],
  selectedDecisionFlow: {},
  selectedRecommendationsRun: {},
  showActiveParameterSets: false,
  userParameterSetSchema: {},
  programStats: [],
  decisionConfiguration: null,
  decisionStandardConfigurationsTemplates: null,
  // Settings
  alertsConditionTemplates: [],
  contractBasedAlerts: [],
  contracts: [],
  timeSeries: [],
  timeSeriesBasedAlerts: [],
  userList: [],
  tenantScopeList: [],
  scopeList: [],

  // Scenarios
  contractIdsWithScenarioProductionFlows: [],
  scenarioRuns: [],
  scenariosOutputs: null,
  scenariosProductionFlows: [],
  selectedScenarioRun: null,
  //Situation
  situation: {},
  selectedSituationDate: null,
  selectedGlobalSituationYear: { id: null, label: null },
  marketsList: [],
  selectedMarket: {},
  marketDemands: [],
  marketDemandSelectedPeriod: { id: '1Y', label: '1 Year' },
  marketTransactions: [],
  marketTransactionsAveragePrice: [],
  marketTransactionsPeriod: { id: '7D', label: '7D' },
  marketContracts: [],
  marketReferenceContract: {},
  marketCurrency: {},
  marketReferenceContractOverview: {
    lastPrice: 0,
    priceChange: 0,
    changeRate: 0,
  },
  transactionsStats: [],
  situationPeriods: [],
  situationDashBoardSelectedPeriod: {},
  marketsListGraph: [],
  // Others
  isTenantApp: false,
  clientList: [],
  globalSnackBars: [],
};

export const dataReducer = (state, { type, payload }) => {
  switch (type) {
    case 'init': {
      const nextState = { ...payload };
      saveToSStore(nextState, STORAGE_KEY);
      return nextState;
    }
    case 'setData': {
      const { dataType, data } = payload;

      if (dataType in state) {
        const nextState = {
          ...state,
          [dataType]: data,
        };
        // saveToSStore(nextState, STORAGE_KEY);
        return nextState;
      }

      console.warn(
        `dataType "${dataType}" is not handled in "src/services/data/reducer.js"`
      );
      return state;
    }
    case 'setMultiData': {
      const { data } = payload;

      Object.keys(data).forEach((key) => {
        // eslint-disable-next-line no-prototype-builtins
        if (!state.hasOwnProperty(key)) {
          if (isDev) {
            console.warn(
              `The data key "${key}" is not handled in src/services/data/reducer.js`
            );
          }
        }
      });

      const nextState = {
        ...state,
        ...data,
      };
      // saveToSStore(nextState, STORAGE_KEY);
      return nextState;
    }
    case 'setLoading': {
      const { name, value } = payload;

      const nextState = {
        ...state,
        loadingStates: {
          ...state.loadingStates,
          [name]: value,
        },
      };

      return nextState;
    }
    case 'setSelectedRun': {
      const { run } = payload;
      const nextState = {
        ...state,
        selectedRun: run,
      };
      return nextState;
    }
    case 'setCurrency': {
      const { currency } = payload;
      const nextState = {
        ...state,
        currency: currency,
      };
      return nextState;
    }
    case 'addGlobalSnackBar': {
      const { globalSnackBar } = payload;
      const nextState = {
        ...state,
        globalSnackBars: [...state.globalSnackBars, globalSnackBar],
      };
      return nextState;
    }

    case 'removeGlobalSnackBar': {
      const { id } = payload;
      const newGlobalSnackBars = [...state.globalSnackBars].filter(
        (item) => item.id !== id
      );
      const nextState = {
        ...state,
        globalSnackBars: newGlobalSnackBars,
      };
      return nextState;
    }

    case 'setTargetOverview': {
      const { lastPrice, priceChange, changeRate } = payload;
      const nextState = {
        ...state,
        targetOverview: {
          lastPrice,
          priceChange,
          changeRate,
        },
      };
      return nextState;
    }
    case 'reset': {
      const nextState = { ...initialState };
      return nextState;
    }
    case 'setIsTenantApp': {
      const isTenantApp = payload;
      const nextState = {
        ...state,
        isTenantApp: isTenantApp,
      };
      return nextState;
    }
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};
